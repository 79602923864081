import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { withPreview } from '@prismicio/gatsby-source-prismic-graphql'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../utils/linkResolver'
import get from 'lodash/get'

let newDate = new Date()
let year = newDate.getFullYear();

const footerQuery = graphql`
  query {
    prismic {
      allSocials {
        edges {
          node {
            body {
              ... on PRISMIC_SocialBodySocial_network {
                primary {
                  social_network
                  social_network_url {
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
      allNavigations {
        edges {
          node {
            _meta {
              uid
              tags
            }
            company_display_name
            company_address
            company_phone
            body {
              ... on PRISMIC_NavigationBodyFooter_link_list {
                fields {
                  link_label
                  link {
                    ... on PRISMIC_Page {
                      _meta {
                        uid
                        type
                        tags
                        lang
                      }
                    }
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                    ... on PRISMIC__FileLink {
                      name
                      url
                      size
                    }
                  }
                }
                primary {
                  title
                }
              }
            }
          }
        }
      }
    }
  }
`;


export const Footer = () => (
  <StaticQuery
    query={`${footerQuery}`}
    render={withPreview(data => {

      const navigationData = data.prismic.allNavigations.edges[0].node
      const socialData = data.prismic.allSocials.edges[0].node
      return (
        <footer role='banner' className='bg-green-700 py-12 px-4 sm:px-6 lg:py-16 lg:px-8 sm:mt-4 lg:mt-8'>
          <div className='max-w-screen-xl mx-auto text-green-100 print:text-black'>
            <div className='xl:grid xl:grid-cols-2 xl:gap-8'>
              <div className='xl:col-span-1'>
                <h4 className='font-serif font-normal uppercase text-lg mb-4 print:text-black text-yellow-200'>{get(navigationData, 'company_display_name')} &copy; {year}</h4>
                <div className='text-muted'>
                  {get(navigationData, 'company_address') && RichText.render(get(navigationData, 'company_address'), linkResolver)}
                  {get(navigationData, 'company_phone') && (
                    <div className='mt-2'>Telephone: <a className='hover:text-yellow-100 hover:underline' href={`tel:${get(navigationData, 'company_phone')}`}>{get(navigationData, 'company_phone')}</a></div>
                  )}
                </div>
                <div className='flex justify-start mt-8 print:hidden'>
                  {socialData.body.map((social, index) => {
                    return (
                      <a key={`social-${index}`} href={get(social, 'primary.social_network_url.url')} target='_blank' rel='noopener noreferrer' className='social-icon'>
                        <img src={require(`../images/${social.primary.social_network.toLowerCase()}.png`)} alt={get(social ,'primary.social_network')} className={`social-${get(social ,'primary.social_network')} w-8 mr-2`} loading='lazy' />
                      </a>
                    )
                  })}
                </div>
              </div>
              <div className='mt-12 grid grid-cols-3 gap-8 xl:mt-0 xl:col-span-1 print:hidden'>
                {navigationData.body.map((footerList, index) => {
                  return (
                    <div key={`footerList-${index}`} className='col-span-3 md:col-span-1'>
                      <h4 className='font-serif font-normal uppercase text-lg mb-4 text-yellow-200'>{footerList.primary.title}</h4>
                      <ul>
                        {footerList.fields.map((footerLink, index) => {
                          var link = get(footerLink.link, 'url',(linkResolver(get(footerLink.link, '_meta',[]),[])))
                          return (
                            <li key={`footer-${index}`}>
                              <a href={link} className='block py-1 my-1 hover:underline hover:text-yellow-100'>{get(footerLink, 'link_label')}</a>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </footer>
      )
    }, footerQuery)}
  />
);

export default Footer
