import React, { PureComponent } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import { withPreview } from '@prismicio/gatsby-source-prismic-graphql'
import get from 'lodash/get'
import { linkResolver } from '../utils/linkResolver'
import logo from '../images/uk-toy-and-model-auctions-ltd-logo-small.png'


const headerQuery = graphql`
  {
    prismic {
      allNavigations {
        edges {
          node {
            company_address
            company_display_name
            company_phone
            nav {
              ... on PRISMIC_NavigationNavNav_item {
                primary {
                  label
                  link {
                    ... on PRISMIC_Page {
                      _meta {
                        uid
                        type
                        tags
                        lang
                      }
                    }
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                }
                fields {
                  sub_nav_link_label
                  sub_nav_link {
                    ... on PRISMIC_Page {
                      _meta {
                        uid
                        type
                        tags
                        lang
                      }
                    }
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;


const Header = ({pageName}) => {
  return (
    <React.Fragment>
      <RenderBody pageName={pageName} />
    </React.Fragment>
  )
}

class RenderBody extends PureComponent {
  constructor() {
    super()
    this.state = {
      mainNavExpanded: false,
      subNavExpanded: false
    }
    this.toggleMainNav = this.toggleMainNav.bind(this);
    this.toggleSubNav = this.toggleSubNav.bind(this);
  }
  toggleMainNav() {
    this.setState({
      mainNavExpanded:!this.state.mainNavExpanded
    })
  }
  toggleSubNav(id) {
    if (id === this.state.subNavExpandedId) {
      this.setState({
        subNavExpandedId:false
      })
    } else {
      this.setState({
        subNavExpandedId:id
      })
    }
  }

  render() {
    return (
      <StaticQuery
        query={`${headerQuery}`}
        render={withPreview(data => {
          const headerData = data.prismic.allNavigations.edges[0].node
          return (
            <React.Fragment>
              <header className='bg-yellow-100 z-10 lg:flex flex-col justify-center' role='banner'>
                <nav className='px-4 sm:px-0 relative w-full'>
                  <div className='z-20 flex justify-center pt-6 pb-2 lg:py-6 relative'>
                    <Link to='/' className='h-16 md:h-24 block'><img src={logo} alt={`${headerData.company_display_name} Logo`} className='h-full' /></Link>
                  </div>
                  <div className='block lg:hidden print:hidden relative text-center z-30'>
                    <button type='button' aria-expanded={this.state.mainNavExpanded} onClick={ this.toggleMainNav } onKeyDown={ this.toggleMainNav } className='px-4 mb-2 py-2 lg:px-1 font-bold border rounded-lg border-green-500'>Menu</button>
                  </div>
                  <div className='bg-green-500 print:hidden absolute bg-yellow-100 text-yellow-100 fixed z-10 inset-x-0 py-5 lg:py-0 w-full lg:relative lg:inset-auto lg:h-auto lg:flex lg:flex-auto lg:items-center lg:w-auto' hidden={!this.state.mainNavExpanded}>
                    <ul className='flex flex-col justify-top inset-0 lg:flex-row lg:justify-center lg:relative lg:text-base w-full'>
                      {headerData.nav.map((nav, index) => {
                        var link = get(nav.primary.link, 'url',(linkResolver(get(nav.primary.link, '_meta',[]),[])))
                        return (
                          <React.Fragment key={`nav-${index}`}>
                            {nav.fields.length === 0 ? (
                              <li key={`nav-${index}`} className='block mx-8 text-2xl lg:text-lg font-bold'>
                                {get(nav.primary.link, 'url' ) ? (
                                  <a href={link} className='py-4 text-yellow-100 hover:text-yellow-500 focus:outline-none focus:text-yellow-200 transition duration-150 ease-out flex flex-row' onClick={ this.toggleNav }>{nav.primary.label}</a>
                                ):(
                                  <Link to={link} className='py-4 text-yellow-100 hover:text-yellow-500 focus:outline-none focus:text-yellow-200 transition duration-150 ease-out flex flex-row' activeClassName='active' onClick={ this.toggleNav }>{nav.primary.label}</Link>
                                )}
                              </li>
                            ):(
                              <li className='block mx-8 text-2xl lg:text-lg font-bold'>
                                <button type='button' className='font-bold py-4 text-yellow-100 hover:text-yellow-500 focus:outline-none focus:text-yellow-200 transition duration-150 ease-out flex flex-row items-baseline' aria-haspopup='true' aria-expanded={this.state.subNavExpanded} onClick={() => this.toggleSubNav(`${nav.primary.label}`)} onKeyDown={() => this.toggleSubNav(`${nav.primary.label}`)}>{nav.primary.label} <svg xmlns='http://www.w3.org/2000/svg' className='w-5' height='1em' width='1em'><path d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z' fill='currentColor'/><path d='M0 0h24v24H0z' fill='none'/></svg></button>
                                <ul className={`md:absolute md:bg-green-600 md:p-3 md:shadow ${this.state.subNavExpandedId === nav.primary.label ? 'block': 'hidden'}`}>
                                  {nav.fields.map((subNav, index) => {
                                    var subLink = get(subNav.sub_nav_link, 'url',(linkResolver(get(subNav.sub_nav_link, '_meta',[]),[])))
                                    return (
                                      <React.Fragment key={`subNav-${index}`}>
                                        <li>
                                          {get(subNav.sub_nav_link, 'url' ) ? (
                                            <a href={subLink} className='whitespace-no-wrap block px-10 md:px-3 lg:px-4 py-2 hover:text-yellow-500 focus:outline-none focus:text-yellow-200 transition duration-150 ease-in-out' onClick={() => this.toggleSubNav(`${nav.primary.label}`)}>{subNav.sub_nav_link_label}</a>
                                          ):(
                                            <Link to={subLink} className='whitespace-no-wrap block px-10 md:px-3 lg:px-4 py-2 hover:text-yellow-500 focus:outline-none focus:text-yellow-200 transition duration-150 ease-in-out' activeClassName='active' onClick={() => this.toggleSubNav(`${nav.primary.label}`)}>{subNav.sub_nav_link_label}</Link>
                                          )}
                                        </li>
                                      </React.Fragment>
                                    )
                                  })}
                                </ul>
                              </li>
                            )}
                          </React.Fragment>
                        )
                      })}
                    </ul>
                  </div>
                </nav>
              </header>
            </React.Fragment>
          )
        }, headerQuery)}
      />
    )
  }
}

RenderBody.defaultProps = {
  loggedIn: false
}

export default Header
