
import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import CookieConsent from 'react-cookie-consent'
import Header from './header'
import Footer from './footer'
import '../styles/default.scss'


const layoutQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

const Layout = props => {
  return (
    <StaticQuery
      query={`${layoutQuery}`}
      render={data => (
        <div className='antialiased text-gray-800 print:text-black bg-white'>
          <Header siteTitle={data.site.siteMetadata.title} pageName={props.pageName} />
          <main role='main' id='main'>{props.children}</main>
          <Footer />
          <div id='fb-root'></div>
          <CookieConsent
            location='bottom'
            buttonText='Accept all cookies'
            enableDeclineButton
            declineButtonText='Decline'
            buttonClasses='rounded-md px-4 py-2 font-bold rounded-full button text-green-800 bg-yellow-200 border-green-900 hover:bg-green-700 hover:text-yellow-200 border border-transparent leading-6 font-medium transform duration-300 ease-in-out transition'
            declineButtonClasses='mx-1 font-bold px-4 py-2'
            containerClasses='z-10 fixed inset-x-0 text-white bg-green-900 p-3 text-sm text-center items-baseline sm:flex justify-center'
            contentClasses='py-2'
            disableStyles
            cookieName='gatsby-gdpr-google-analytics'>
            We use cookies to collect information about how you use {data.site.siteMetadata.title}. We use this information to make the website work as well as possible and improve our services.
            </CookieConsent>
        </div>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageName: PropTypes.string
}

export default Layout
